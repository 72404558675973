export const choices = [
  {
    id: 1,
    title: 'มีเจ้าหน้าที่มาคัดแยก<br/>ให้ที่บ้านทุกปี',
    content:
      'พยาบาลหรืออาสาสมัครสาธารณสุขประจำหมู่บ้าน (อสม.) จะเดินทางมาตรวจคัดแยกให้ที่บ้านเป็นรายปี เหมาะกับกรณีที่ไม่รีบเร่งที่จะ ต้องเข้ารับการคัดแยกทันที หรืออาจจะไม่สะดวก เดินทางไปเข้ารับการคัดแยกที่โรงพยาบาล',
    text: 'แบบ ก',
    thumbnail_url: '/images/choice/choice-a.svg',
    image_url: '/images/choice/s3-01.jpg',
    code: 'ก',
    column: 'choiceA',
    index: 0,
    value: 'a',
    prev: null,
    next: 1,
  },
  {
    id: 2,
    title: 'คัดแยกที่จุดบริการ<br/>ตามสถานที่ต่างๆ',
    content:
      'เปิดจุดคัดแยกตามสถานที่ต่างๆ ที่ใกล้ชุมชน และเดินทางสะดวก เช่น ห้างสรรพสินค้า โดย จะมีเจ้าหน้าที่เป็นผู้คัดแยกให้โดยตรง',
    text: 'แบบ ข',
    thumbnail_url: '/images/choice/choice-b.svg',
    image_url: '/images/choice/s3-02.jpg',
    code: 'ข',
    column: 'choiceB',
    index: 1,
    value: 'b',
    prev: 0,
    next: 2,
  },
  {
    id: 3,
    title: 'คัดแยกด้วยตนเอง<br/>ผ่านระบบออนไลน์',
    content:
      'สามารถรู้ผลการคัดแยกได้ทันทีด้วยระบบการ คัดแยกออนไลน์ ซึ่งพัฒนาและทดสอบแล้วโดย แพทย์ที่มีความเชี่ยวชาญ คุณสามารถประเมิน ตนเอง หรือจะเปิดแบบประเมินออนไลน์เพื่อ ให้คนที่คุณรักทำที่ไหนก็ได้ ',
    text: 'แบบ ค',
    thumbnail_url: '/images/choice/choice-c.svg',
    image_url: '/images/choice/s3-03.jpg',
    code: 'ค',
    column: 'choiceC',
    index: 2,
    value: 'c',
    prev: 1,
    next: 3,
  },
  {
    id: 4,
    title: 'คัดแยกร่วมกับ<br/>การตรวจสุขภาพประจำปี',
    content:
      'คุณใส่ใจสุขภาวะของตนเองและตรวจสุขภาพ ประจำปีอยู่แล้ว ภาวะสมองเสื่อมเป็นอีกเรื่อง ที่คุณอยากรู้พร้อมกับภาวะอื่นๆ ในร่างกาย โดย ไม่จำเป็นต้องรีบรู้ผลในทันทีว่ามีภาวะสมองเสื่อมหรือไม่',
    text: 'แบบ ง',
    thumbnail_url: '/images/choice/choice-d.svg',
    image_url: '/images/choice/s3-04.jpg',
    code: 'ง',
    column: 'choiceD',
    index: 3,
    value: 'd',
    prev: 2,
    next: 4,
  },
  {
    id: 5,
    title: 'โทรนัดหมายให้เจ้าหน้าที่<br/>มาทำการคัดแยก',
    content:
      'หากไม่สะดวกเดินทางออกจากบ้าน ไม่ว่าจะ เพราะอยู่ในพื้นที่ห่างไกล หรือเพราะไม่สามารถ เคลื่อนไหวได้ถนัดนัก แต่ต้องการจะรู้ผลภาวะ ทางสมองทันที คุณจึงเลือกใช้บริการโทร นัดหมายให้เจ้าหน้าที่มาตรวจที่บ้าน ',
    text: 'แบบ จ',
    thumbnail_url: '/images/choice/choice-e.svg',
    image_url: '/images/choice/s3-05.jpg',
    code: 'จ',
    column: 'choiceE',
    index: 4,
    value: 'e',
    prev: 3,
    next: null,
  },
]
